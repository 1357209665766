import React, { useEffect } from 'react';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';

import { usePrevious } from 'src/common/hooks';

import { ExpertApplication } from '../models/ExpertApplication';
import {
  ApplicationFormLayout,
  ApplicationFormProgressContainer,
  ApplicationFormValueContainer,
  Spacing,
} from '../styles.sc';
import { CurrentJobSection } from './CurrentJobSection/CurrentJobSection';
import { useExpertApplicationDraft } from './hooks/useExpertApplicationDraft';
import { useSubmitExpertApplication } from './hooks/useSubmitExpertApplication';
import { ProgressPanel } from './ProgressPanel/ProgressPanel';
import { FormValues } from './types';
import { WorkingExperienceSection } from './WorkingExperienceSection/WorkingExperienceSection';

type Props = {
  expertApplication: ExpertApplication;
};

const getFormValuesFromExpertApplication = ({
  additionalInformation,
  user,
  workExperience,
}: ExpertApplication): FormValues => {
  const formValues: FormValues = {
    workingExperience: {
      totalYearsOfExperience:
        additionalInformation?.selfDeclaredYearsOfExperience ?? '',
      linkedInProfileUrl: user?.portfolio?.socialNetwork?.LinkedIn ?? '',
    },
    currentJob: {
      organization: workExperience?.organization ?? '',
      companySize:
        workExperience?.expertApplicationWorkExperience?.companySize ?? '',
      title: workExperience?.title ?? '',
      jobLevel: workExperience?.expertApplicationWorkExperience?.jobLevel ?? '',
      startDate: {
        startMonth: workExperience?.startDate
          ? moment.utc(workExperience?.startDate).format('MMMM')
          : '',
        startYear: workExperience?.startDate
          ? moment.utc(workExperience?.startDate).format('YYYY')
          : '',
      },
    },
  };
  return formValues;
};

export const ExpertApplicationForm = ({ expertApplication }: Props) => {
  const { expertApplicationDraft, setExpertApplicationDraft, clearDraft } =
    useExpertApplicationDraft(expertApplication?.dateOfSubmission);

  const prevExpertApplication = usePrevious(expertApplication);

  const expertApplicationForm = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues:
      expertApplicationDraft ??
      getFormValuesFromExpertApplication(expertApplication),
  });
  const { watch, reset, trigger, handleSubmit } = expertApplicationForm;

  useEffect(
    function saveToLocalDraftCache() {
      const subscription = watch((value, { type }) => {
        if (type === 'change') {
          setExpertApplicationDraft(value);
        }
      });
      return () => subscription.unsubscribe();
    },
    [watch, setExpertApplicationDraft]
  );

  useEffect(
    function validateOnLoad() {
      trigger();
    },
    // revalidate when expertApplication is changes
    [trigger, expertApplication]
  );

  useEffect(
    function resetIfExpertApplicationChanges() {
      if (
        prevExpertApplication !== undefined &&
        expertApplication !== prevExpertApplication
      ) {
        reset(
          expertApplicationDraft ??
            getFormValuesFromExpertApplication(expertApplication)
        );
      }
    },
    [expertApplication, expertApplicationDraft, prevExpertApplication, reset]
  );

  const { submitExpertApplication, submitExpertApplicationInProcess } =
    useSubmitExpertApplication();
  const submitForm = async (newFormValues: FormValues) => {
    await submitExpertApplication(newFormValues, expertApplication);
    clearDraft();
  };

  return (
    <FormProvider {...expertApplicationForm}>
      <form>
        <ApplicationFormLayout>
          <ApplicationFormValueContainer>
            <WorkingExperienceSection />
            <Spacing value={40} />
            <CurrentJobSection />
          </ApplicationFormValueContainer>
          <ApplicationFormProgressContainer>
            <ProgressPanel
              onSubmit={handleSubmit(submitForm)}
              isSubmitInProgress={submitExpertApplicationInProcess}
            />
          </ApplicationFormProgressContainer>
        </ApplicationFormLayout>
      </form>
    </FormProvider>
  );
};
