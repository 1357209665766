import React, { PropsWithChildren } from 'react';
import { FormattedMessage, FormattedMessageProps } from 'react-intl';

import { Spacing } from '../../styles.sc';
import { StyledLabelMessage } from './ApplicationFieldContainer.sc';

type ApplicationFieldContainerProps = {
  fieldLabel: FormattedMessageProps;
  isRequired: boolean;
  labelFor?: string;
};

const ApplicationFieldContainerComponent = ({
  children,
  fieldLabel,
  labelFor,
  isRequired,
}: PropsWithChildren<ApplicationFieldContainerProps>) => {
  return (
    <>
      <label htmlFor={labelFor}>
        <StyledLabelMessage isRequired={isRequired}>
          <FormattedMessage {...fieldLabel} />
        </StyledLabelMessage>
      </label>
      <Spacing value={8} />
      {children}
    </>
  );
};

export const ApplicationFieldContainer = React.memo(
  ApplicationFieldContainerComponent
);
