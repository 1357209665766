import { gql } from '@apollo/client';

export const cancelExpertApplication = gql`
  mutation cancelExpertApplication {
    cancelExpertApplication {
      id
      status
    }
  }
`;
