import { Greyscale } from 'glints-aries';
import styled from 'styled-components';

export const StyledLegend = styled.legend<{ isRequired: boolean }>`
  font-size: 16px;
  color: ${Greyscale.black};
  ${({ isRequired }) =>
    isRequired
      ? `&::after {
        content: "*";
        color: red;
      }`
      : ''}
`;

export const Fieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
`;
