import { gql, useMutation } from '@apollo/client';
import moment from 'moment';
import * as yup from 'yup';
import { Asserts } from 'yup';

import {
  additionalInformationFragment,
  experienceFragment,
  userFragment,
} from '../../graphql';
import {
  ExpertApplication,
  ExpertApplicationCompanySize,
  ExpertApplicationCompanySizeType,
  ExpertApplicationJobLevel,
  ExpertApplicationJobLevelType,
  ExpertApplicationYearsOfExperience,
  ExpertApplicationYearsOfExperienceType,
} from '../../models/ExpertApplication';
import { FormValues } from '../types';

const sendExpertApplication = gql`
  mutation sendExpertApplication($data: ExpertApplicationInput!) {
    sendExpertApplication(data: $data) {
      id
      status
      dateOfSubmission
      additionalInformation {
        ...additionalInformationFragment
      }
      user {
        ...userFragment
      }
      workExperience {
        ...experienceFragment
      }
    }
  }
  ${additionalInformationFragment}
  ${userFragment}
  ${experienceFragment}
`;

const ExpertApplicationCurrentJobInputSchema = yup.object({
  experienceId: yup.string().notRequired(), // Not null if we are updating an experience
  organization: yup.string().required(),
  companySize: yup.mixed().oneOf(Object.values(ExpertApplicationCompanySize)),
  title: yup.string().required(),
  jobLevel: yup.mixed().oneOf(Object.values(ExpertApplicationJobLevel)),
  startDate: yup.string().required(),
});

interface ExpertApplicationCurrentJobInput
  extends Asserts<typeof ExpertApplicationCurrentJobInputSchema> {
  experienceId: string | undefined; // Not null if we are updating an experience
  organization: string; // Company Name
  companySize: ExpertApplicationCompanySizeType;
  title: string; // Position
  jobLevel: ExpertApplicationJobLevelType;
  startDate: string;
}

const ExpertApplicationInputSchema = yup.object({
  totalYearsOfExperience: yup
    .mixed()
    .oneOf(Object.values(ExpertApplicationYearsOfExperience)),
  linkedInProfileUrl: yup.string().required(),
  currentJob: ExpertApplicationCurrentJobInputSchema,
});

interface ExpertApplicationInput
  extends Asserts<typeof ExpertApplicationInputSchema> {
  totalYearsOfExperience: ExpertApplicationYearsOfExperienceType;
  linkedInProfileUrl: string;
  currentJob: ExpertApplicationCurrentJobInput;
}

export const useSubmitExpertApplication = () => {
  const [mutate, { data, loading }] = useMutation<
    {
      sendExpertApplication: Omit<ExpertApplication, 'rejectionReasons'>;
    },
    {
      data: ExpertApplicationInput;
    }
  >(sendExpertApplication);

  return {
    submitExpertApplication: (
      newFormValues: FormValues,
      oldExpertApplication: ExpertApplication
    ) => {
      const {
        workingExperience: { totalYearsOfExperience, linkedInProfileUrl },
        currentJob,
      } = newFormValues;
      const {
        startDate: { startMonth, startYear },
      } = currentJob;
      const data: ExpertApplicationInput =
        ExpertApplicationInputSchema.validateSync({
          totalYearsOfExperience,
          linkedInProfileUrl,
          currentJob: {
            ...currentJob,
            experienceId: oldExpertApplication.workExperience?.id,
            startDate: moment()
              .year(Number(startYear))
              .month(startMonth)
              .startOf('month')
              .utc()
              .toISOString(),
          },
        });
      return mutate({
        variables: {
          data,
        },
      });
    },
    submitExpertApplicationResponse: data,
    submitExpertApplicationInProcess: loading,
  } as const;
};
