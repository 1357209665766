import { SecondaryColor } from 'glints-aries';
import styled from 'styled-components';

export const ChecklistItemContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 0 10px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border: 0;
  border-bottom: 1px solid #dedede;
  color: ${SecondaryColor.actionblue};
`;
