import { useMutation } from '@apollo/client';

import { updateExpertApplicationPersonalInformation } from '../graphql';
import { ExpertApplication } from '../models/ExpertApplication';
import { UpdateExpertPersonalInformationInput } from '../models/inputs/UpdateExpertPersonalInformationInput';

export const useUpdateExpertApplicationPersonalInformation = () => {
  const [updatePersonalInformation, { data, loading }] = useMutation<
    {
      updateExpertApplicationPersonalInformation: ExpertApplication;
    },
    { data: UpdateExpertPersonalInformationInput }
  >(updateExpertApplicationPersonalInformation);
  return {
    data,
    loading,
    updatePersonalInformation,
  };
};
