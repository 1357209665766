import React from 'react';
import { Select } from 'glints-aries';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Control, useController, useWatch } from 'react-hook-form';
import { defineMessage, FormattedMessageProps, useIntl } from 'react-intl';

import { ApplicationMultiFieldContainer } from 'src/modules/ExpertPlatform/Components/ApplicationMultiFieldsContainer/ApplicationMultiFieldsContainer';
import { requiredMessage } from 'src/modules/ExpertPlatform/constants';
import { useYearAndMonthOptions } from 'src/modules/Profile/Components/Form/useYearAndMonthOptions';

import { FormValues } from '../../types';
import { TwoColumns } from './StartMonthAndYearFields.sc';

const legendText = defineMessage({
  id: 'expert-work-experince_start_date',
  defaultMessage: 'Start Date',
});

const startMonthPlaceHolder = defineMessage({
  id: 'expert-work-experience_start-date_select-month',
  defaultMessage: 'Select Month',
});

const startYearPlaceHolder = defineMessage({
  id: 'expert-work-experience_start-date_select-year',
  defaultMessage: 'Select Year',
});

type RequiredSelectorProps = {
  control: Control<FormValues>;
  name: 'currentJob.startDate.startMonth' | 'currentJob.startDate.startYear';
  placeholder: FormattedMessageProps;
  options: { dataValue: string; label: string }[];
};

const DatePartsSelector = ({
  control,
  options,
  placeholder,
  name,
}: RequiredSelectorProps) => {
  const intl = useIntl();

  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    control,
    rules: {
      validate: (value) =>
        isEmpty(value) ? intl.formatMessage(requiredMessage) : undefined,
    },
  });

  const showError = error && isTouched;
  const chosenValue = options.find(
    ({ dataValue }) => field.value === dataValue
  );
  return (
    <>
      <Select
        {...field}
        id={name}
        name={name}
        value={chosenValue?.label ?? ''}
        error={showError && error?.message}
        disableTyping={true}
        placeholder={intl.formatMessage(placeholder)}
        removeFloatingLabel={true}
      >
        {options.map(({ dataValue, label }) => (
          <Select.Option value={dataValue} key={dataValue}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

const Component = ({ control }: { control: Control<FormValues> }) => {
  const { monthOptions, yearOptions } = useYearAndMonthOptions();
  const selectedYear = useWatch({
    control,
    exact: true,
    name: 'currentJob.startDate.startYear',
  });

  const selectedMonth = useWatch({
    control,
    exact: true,
    name: 'currentJob.startDate.startMonth',
  });
  const nonFutureMonths = selectedYear
    ? monthOptions.filter(
        ({ dataValue }) =>
          moment()
            .year(Number(selectedYear))
            .month(dataValue)
            .startOf('month') <= moment()
      )
    : undefined;
  const nonFutureYears = selectedMonth
    ? yearOptions.filter(
        ({ dataValue }) =>
          moment()
            .year(Number(dataValue))
            .month(selectedMonth)
            .startOf('month') <= moment()
      )
    : undefined;
  return (
    <>
      <ApplicationMultiFieldContainer isRequired={true} legendText={legendText}>
        <TwoColumns>
          <DatePartsSelector
            name="currentJob.startDate.startMonth"
            control={control}
            options={nonFutureMonths ?? monthOptions}
            placeholder={startMonthPlaceHolder}
          />
          <DatePartsSelector
            name="currentJob.startDate.startYear"
            control={control}
            options={nonFutureYears ?? yearOptions}
            placeholder={startYearPlaceHolder}
          />
        </TwoColumns>
      </ApplicationMultiFieldContainer>
    </>
  );
};

export const StartMonthAndYearFields = React.memo(Component);
