import React from 'react';
import { CheckmarkThickIcon, Greyscale, SecondaryColor } from 'glints-aries';

import { ChecklistItemContainer } from './ChecklistItem.sc';

type ChecklistItemProps = {
  label: string;
  isCompleted: boolean;
};
export const ChecklistItem = React.memo(function ChecklistItemComponent({
  label,
  isCompleted,
}: ChecklistItemProps) {
  const checkMarkColor = isCompleted
    ? SecondaryColor.green
    : Greyscale.lightgrey;
  return (
    <ChecklistItemContainer>
      <CheckmarkThickIcon color={checkMarkColor} height={20} width={20} />
      {label}
    </ChecklistItemContainer>
  );
});
