import React from 'react';
import { ScreenSize } from 'glints-aries';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { FormattedMessage } from 'react-intl';

import useScrollToTopOnMount from 'src/common/hooks/ScrollRestoration/useScrollToTopOnMount';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { MetadataRenderer } from 'src/components/MetaDescription/MetadataRenderer';

import * as Styled from './ExpertClassApplicationSentPage.sc';

const Footer = dynamic(() => import('src/components/Footer/FooterPsychFlat'));

export const ExpertApplicationSentPage: NextPage = () => {
  useScrollToTopOnMount();
  const isMobile = useMediaQuery(`(max-width: ${ScreenSize.mobileL}px)`);

  const meta = {
    title: 'Expert Application Sent',
    name: {
      robots: 'noindex',
    },
  };

  return (
    <>
      <MetadataRenderer {...meta} />
      <Styled.Container>
        <Styled.Image url="/images/expert-application/expert-application-sent-bg.png" />

        <FormattedMessage
          id="expert-application-sent_title"
          defaultMessage="Your application has been sent!"
          tagName={Styled.Title}
        />

        <FormattedMessage
          id="expert-application-sent_description"
          defaultMessage="What's next?{br}We will review your application and get back to you.{br}
          Application approval will be communicated via email.{br}Thank you!"
          tagName={Styled.Description}
          values={{ br: isMobile ? ' ' : <br /> }}
        />
      </Styled.Container>
      <Footer />
    </>
  );
};
