import React from 'react';
import { FormattedMessage, FormattedMessageProps } from 'react-intl';

import * as S from './styles.sc';

type Props = {
  ctaText: FormattedMessageProps;
  contentText: Pick<FormattedMessageProps, 'defaultMessage' | 'id'>;
  onCTAClick: () => void;
  ctaDataTestId?: string;
};
export const ExpertGetStarted = ({
  ctaText,
  onCTAClick,
  ctaDataTestId,
  contentText,
}: Props) => {
  return (
    <S.Container>
      <S.Image url="/images/expert-application/expert-login-bg.png" />
      <S.Column>
        <FormattedMessage
          id="expert-login_title"
          tagName={S.Title}
          defaultMessage="WELCOME!"
        />
        <FormattedMessage
          id="expert-login_subtitle"
          tagName={S.SubTitle}
          defaultMessage="THE JOURNEY TO BECOMING AN EXPERT BEGINS HERE"
        />

        <FormattedMessage tagName={S.Description} {...contentText} />
        <S.Button onClick={onCTAClick} data-testid={ctaDataTestId}>
          <FormattedMessage {...ctaText} />
        </S.Button>
      </S.Column>
    </S.Container>
  );
};
