import { ResourceLinks } from './ResourceLinks';

export enum CandidateStatus {
  I_AM_NOT_LOOKING_FOR_A_JOB = 'I_AM_NOT_LOOKING_FOR_A_JOB',
  OPEN_FOR_OPPORTUNITIES = 'OPEN_FOR_OPPORTUNITIES',
  I_AM_LOOKING_FOR_JOB = 'I_AM_LOOKING_FOR_JOB',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  PREFER_NOT_TO_SHARE = 'PREFER_NOT_TO_SHARE',
  OTHER = 'OTHER',
}

export enum UserRole {
  CANDIDATE = 'CANDIDATE',
  COMPANY = 'COMPANY',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum SocialNetworkTypes {
  Behance = 'Behance',
  CodePen = 'CodePen',
  Facebook = 'Facebook',
  GitHub = 'GitHub',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  TikTok = 'Tiktok',
  Twitter = 'Twitter',
  Vimeo = 'Vimeo',
  Youtube = 'Youtube',
}

export interface PortfolioAttachment {
  fileName: string;
  filePathname: string;
}

export type SocialNetwork = {
  [type in SocialNetworkTypes]: string;
};
export interface Portfolio {
  attachment?: PortfolioAttachment[];
  socialNetwork?: SocialNetwork;

  // Legacy fields for portfolio.
  document?: string;
  website?: string;
}

export interface User extends ResourceLinks {
  email: string;
  isVerified: boolean;
  birthDate: string;
  age: number;
  id: string;
  emailVerificationTokenExpiry: string;
  metadata: any;
  firstName: string;
  lastName: string;
  LocationId?: string;
  ugcFullName: string;
  phone: string;
  lastSeen: string;
  preferredLanguage: string;
  intro: string;
  profilePic: string;
  portfolio: Portfolio;
  resume: string;
  role: UserRole;
  gender: Gender;
  race: string;
  isEmployed: boolean;
  isPublic: false;
  slug: string;
  candidateStatus: CandidateStatus;
  CityId: number;
  CountryCode: string;
  candidateStatusExpiryMonth: string;
  candidateStatusExpiryYear: string;
  highestEducationLevel?: string;
  whatsappNumber?: string;
  careerStartDate?: string;
  createdAt: string;
  updatedAt: string;
  image?: string;
  online?: boolean;
  name?: string;
  isHighResponseRate?: boolean;
}

export interface PresentExperience {
  id: string;
  organization: string;
  title: string;
}

export interface Review {
  comment: string;
  reviewDate: string;
}

export interface ExpertRating {
  finalRating: number;
  totalResponseCount: number;
  reviews: Review[];
}

export interface Speaker {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  Experiences?: PresentExperience[];
  profilePic: string;
  ugcFullName: string;
  presentExperiences?: PresentExperience[];
  expertRating?: ExpertRating;
  availableClassCount?: number;
}
