import { useEffect } from 'react';

export default function useScrollToTopOnMount(): undefined {
  useEffect(() => {
    history.scrollRestoration = 'manual';
    window.scrollTo(0, 0);
    return () => {
      history.scrollRestoration = 'auto';
    };
  }, []);

  return undefined;
}
