import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ApplicationFormSection } from '../../Components/ApplicationFormSection/ApplicationFormSection';
import { Spacing } from '../../styles.sc';
import { FormValues, SectionKey } from '../types';
import { LinkedinField } from './LinkedInField';
import { TotalYearsOfExperienceField } from './TotalYearsOfExperienceField';

const id: SectionKey = 'workingExperience';

const Component = () => {
  const { control } = useFormContext<FormValues>();
  return (
    <ApplicationFormSection
      id={id}
      titleMessage={{
        id: 'expert-application_working_experience',
        defaultMessage: 'WORKING EXPERIENCE',
      }}
    >
      <TotalYearsOfExperienceField control={control} />
      <Spacing value={16} />
      <LinkedinField control={control} />
    </ApplicationFormSection>
  );
};

export const WorkingExperienceSection = React.memo(Component);
