import { useMemo } from 'react';
import { merge } from 'lodash';
import moment from 'moment';
import { DeepPartial } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useLocalStorage } from 'src/common/hooks/useLocalStorage';
import { User } from 'src/global/models';
import { getUser } from 'src/selectors/user';

import { ExpertApplication } from '../../models/ExpertApplication';
import { FormValues } from '../types';

export type DraftFormValues = FormValues;

type LocalDraftObj = {
  formValues: DraftFormValues;
  localDraftLastUpdatedAt: string;
  userId: User['id'];
};
const getEmptyDraftFormValues: () => DraftFormValues = () => ({
  workingExperience: {
    totalYearsOfExperience: '',
    linkedInProfileUrl: '',
  },
  currentJob: {
    organization: '',
    companySize: '',
    title: '',
    jobLevel: '',
    startDate: {
      startMonth: '',
      startYear: '',
    },
  },
});
const serializeDraftFormValues = (
  formValues: DeepPartial<DraftFormValues>,
  userId: User['id']
) => {
  const localDraftObj: LocalDraftObj = {
    formValues: merge(getEmptyDraftFormValues(), formValues),
    userId,
    localDraftLastUpdatedAt: moment().toISOString(),
  };
  return JSON.stringify(localDraftObj);
};

type Result = {
  expertApplicationDraft: DraftFormValues | null;
  setExpertApplicationDraft: (
    draftFormValues: DeepPartial<DraftFormValues>
  ) => void;
  clearDraft: () => void;
};

export const useExpertApplicationDraft = (
  dateOfSubmission: ExpertApplication['dateOfSubmission'] | undefined
): Result => {
  const user = useSelector(getUser);
  if (!user.id) {
    throw new Error('User id is needed to save expert application draft');
  }
  const [draft, setDraft] = useLocalStorage(
    `expert-application-draft-${user.id}`
  );
  const clearDraft = () => setDraft(null);
  const setExpertApplicationDraft = (draftFormValues: DraftFormValues) => {
    setDraft(serializeDraftFormValues(draftFormValues, user.id));
  };
  const draftObject = useMemo(
    () => JSON.parse(draft) as LocalDraftObj,
    [draft]
  );
  if (!draft || dateOfSubmission === undefined) {
    return {
      expertApplicationDraft: null,
      setExpertApplicationDraft,
      clearDraft,
    };
  }

  if (dateOfSubmission === null) {
    return {
      expertApplicationDraft: draftObject?.formValues ?? null,
      setExpertApplicationDraft,
      clearDraft,
    };
  }

  const draftLastUpdatedAt = draftObject?.localDraftLastUpdatedAt;
  const staleDraft =
    moment(draftLastUpdatedAt).isValid() &&
    moment(dateOfSubmission).isAfter(moment(draftLastUpdatedAt));
  if (staleDraft) {
    return {
      expertApplicationDraft: null,
      setExpertApplicationDraft,
      clearDraft,
    };
  }
  return {
    expertApplicationDraft: draftObject?.formValues ?? null,
    setExpertApplicationDraft,
    clearDraft,
  };
};
