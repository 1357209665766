import React, { useMemo } from 'react';
import { Select } from 'glints-aries';
import { isEmpty } from 'lodash';
import { Control, FieldPath, useController } from 'react-hook-form';
import {
  defineMessage,
  defineMessages,
  MessageDescriptor,
  useIntl,
} from 'react-intl';

import { ApplicationFieldContainer } from '../../Components/ApplicationFieldContainer/ApplicationFieldContainer';
import { requiredMessage } from '../../constants';
import {
  ExpertApplicationJobLevel,
  ExpertApplicationJobLevelType,
} from '../../models/ExpertApplication';
import { FormValues } from '../types';

const options: ExpertApplicationJobLevelType[] = [
  ExpertApplicationJobLevel.NON_MANAGEMENT,
  ExpertApplicationJobLevel.JUNIOR,
  ExpertApplicationJobLevel.MIDDLE,
  ExpertApplicationJobLevel.SENIOR,
];

const optionLabels: {
  [key in ExpertApplicationJobLevelType]: MessageDescriptor;
} = defineMessages({
  NON_MANAGEMENT: {
    id: 'expert-work-experince_job_level_non_management',
    defaultMessage: 'Non-Management',
  },
  JUNIOR: {
    id: 'expert-work-experince_job_level_junior',
    defaultMessage: 'Junior-level',
  },
  MIDDLE: {
    id: 'expert-work-experince_job_level_mid',
    defaultMessage: 'Mid-level',
  },
  SENIOR: {
    id: 'expert-work-experince_job_level_senior',
    defaultMessage: 'Senior-level',
  },
});

const label = defineMessage({
  id: 'expert-work-experince_job_level',
  defaultMessage: 'Job Level',
});

const placeholder = defineMessage({
  id: 'expert-work-experience_job-level_placeholder',
  defaultMessage: 'Enter job level',
});

type Props = {
  control: Control<FormValues>;
};

const name: FieldPath<FormValues> = 'currentJob.jobLevel';

export const Component = ({ control }: Props) => {
  const intl = useIntl();

  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    control,
    rules: {
      validate: (value) =>
        isEmpty(value) ? intl.formatMessage(requiredMessage) : undefined,
    },
  });

  const chosenValue = useMemo(() => {
    const choice = options.find((option) => option === field.value);
    return choice ? intl.formatMessage(optionLabels[choice]) : '';
  }, [field.value, intl]);

  const showError = error && isTouched;
  return (
    <>
      <ApplicationFieldContainer
        fieldLabel={label}
        isRequired={true}
        labelFor={name}
      >
        <Select
          {...field}
          id={name}
          name={name}
          value={chosenValue}
          error={showError && error?.message}
          disableTyping={true}
          placeholder={intl.formatMessage(placeholder)}
        >
          {options.map((option) => (
            <Select.Option value={option} key={option}>
              {intl.formatMessage(optionLabels[option])}
            </Select.Option>
          ))}
        </Select>
      </ApplicationFieldContainer>
    </>
  );
};

export const JobLevelField = React.memo(Component);
