import React from 'react';

import {
  ProgressCircleComplete,
  ProgressCircleGreen,
  ProgressCircleRed,
  ProgressCircleYellow,
} from './PercentageProgressCircle.sc';

type PercentageProgressCircleProps = {
  percentage: number;
};

export const PercentageProgressCircle = React.memo(
  function PercentageProgressCircleComponent({
    percentage,
  }: PercentageProgressCircleProps) {
    return (
      <Choose>
        <When condition={percentage === 100}>
          <ProgressCircleComplete>{percentage}%</ProgressCircleComplete>
        </When>
        <When condition={percentage <= 99 && percentage >= 75}>
          <ProgressCircleGreen>{percentage}%</ProgressCircleGreen>
        </When>
        <When condition={percentage >= 50 && percentage <= 75}>
          <ProgressCircleYellow>{percentage}%</ProgressCircleYellow>
        </When>
        <When condition={percentage < 50}>
          <ProgressCircleRed>{percentage}%</ProgressCircleRed>
        </When>
      </Choose>
    );
  }
);
