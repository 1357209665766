import styled, { css } from 'styled-components';

const common = css`
  width: 117px;
  height: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 117px 117px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #222222;
`;

export const ProgressCircleRed = styled.div`
  ${common}
  background-image: url(/images/expert-platform/application-progress/progress-red.png);
`;

export const ProgressCircleYellow = styled.div`
  ${common}
  background-image: url(/images/expert-platform/application-progress/progress-yellow.png);
`;

export const ProgressCircleGreen = styled.div`
  ${common}
  background-image: url(/images/expert-platform/application-progress/progress-green.png);
`;

export const ProgressCircleComplete = styled.div`
  ${common}
  background-image: url(/images/expert-platform/application-progress/progress-complete.png);
`;
