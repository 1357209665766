import { Button as GlintsButton, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 80px;
  margin-bottom: 120px;
  text-align: center;
`;

export const Image = styled.div<{ url: string }>`
  width: 160px;
  height: 157px;
  margin: 0px auto;
  background: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
`;

export const ApplicationRejectionTitle = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 30.8px;
  margin-top: 40px;
  @media (max-width: ${ScreenSize.tablet}px) {
    margin: 32px 20px 0px;
  }
`;

export const WhatsNextText = styled.div`
  margin-bottom: 48px;
  font-size: 18px;
  line-height: 27px;

  @media (max-width: ${ScreenSize.tablet}px) {
    margin: 0px 20px 48px;
  }
`;

export const RejectedReasonsContainer = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${ScreenSize.tablet}px) {
    margin: 16px 20px;
  }
`;

export const List = styled.ul`
  overflow: auto;
  list-style-position: outside;
  text-align: left;
  margin: 0px;

  @media (max-width: ${ScreenSize.tablet}px) {
    padding-inline-start: 24px;
  }
`;

export const ListItem = styled.li`
  font-size: 18px;
  line-height: 27px;
`;

export const Button = styled(GlintsButton)`
  @media (max-width: ${ScreenSize.tablet}px) {
    display: block;
    width: 340px;
    margin: 0px auto;
  }
`;
