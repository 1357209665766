import { useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { find } from 'lodash';

import {
  getActiveExpertApplication,
  getOrCreateUnApprovedExpertApplication,
} from '../graphql';
import { ExpertApplication } from '../models/ExpertApplication';

export const useCreateUnApproveExpertApplication = () => {
  const [error, setError] = useState<ApolloError>(null);
  const [mutate, { data, loading }] = useMutation<{
    getOrCreateUnApprovedExpertApplication: ExpertApplication;
  }>(getOrCreateUnApprovedExpertApplication, {
    onError: (error) => {
      setError(error);
    },

    // https://github.com/apollographql/apollo-client/issues/5963#issuecomment-937772294
    refetchQueries: [getActiveExpertApplication],
  });

  const isUserAlreadyExpert = Boolean(
    find(error?.graphQLErrors, {
      extensions: { code: 'USER_ALREADY_AN_EXPERT_ERROR' },
    })
  );

  return {
    mutate,
    data,
    loading,
    error,
    isUserAlreadyExpert,
  } as const;
};
