import { useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { find } from 'lodash';

import { reviseExpertApplication } from '../graphql';
import { ExpertApplication } from '../models/ExpertApplication';

export const useReviseExpertApplication = () => {
  const [error, setError] = useState<ApolloError>(null);
  const [mutate, { data, loading }] = useMutation<{
    reviseExpertApplication: ExpertApplication;
  }>(reviseExpertApplication, {
    onError: (error) => {
      setError(error);
    },
  });

  const isAlreadyRevised = Boolean(
    find(error?.graphQLErrors, {
      extensions: { code: 'OPERATION_NOT_ALLOWED' },
    })
  );
  return {
    mutate,
    loading,
    data,
    error,
    isAlreadyRevised,
  };
};
