import { Greyscale, SecondaryColor } from 'glints-aries';
import Link from 'next/link';
import styled from 'styled-components';

export const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  color: ${Greyscale.black};
`;

export const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 30px;
  color: ${Greyscale.black};
`;

export const ModalContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${Greyscale.black};
`;

export const ModalImage = styled.div`
  width: 160px;
  height: 156px;
  background-size: 160px 156px;
  background-image: url(/images/expert-platform/cancel-application-modal.png);
  margin: 0px auto 20px auto;
`;

export const ExistingClassesLink = styled(Link)`
  color: ${SecondaryColor.actionblue};
  cursor: pointer;
`;
