import { Button as GlintsButton, ScreenSize, Typography } from 'glints-aries';
import styled from 'styled-components';

const { Title: AriesTitle, Paragraph } = Typography;
export const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: center;
  margin-top: 70.79px;
  margin-bottom: 115.21px;

  @media (max-width: ${ScreenSize.tablet}px) {
    flex-flow: column;
    text-align: center;
    margin: 24.38px auto 0px;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  max-width: 600px;

  @media (max-width: ${ScreenSize.tablet}px) {
    text-align: center;
    align-items: center;
  }
`;
export const Image = styled.div<{ url: string }>`
  width: 400px;
  height: 435px;
  margin-right: 45px;
  background: url(${(props) => props.url});
  background-size: cover;
  background-position: center;

  @media (max-width: ${ScreenSize.tablet}px) {
    width: 228px;
    height: 242.99px;
    margin-right: 0px;
  }
`;

export const Title = styled(AriesTitle).attrs({ tag: 'h3' })`
  font-size: 32px;
  font-weight: 800;

  @media (max-width: ${ScreenSize.tablet}px) {
    margin-top: 26.84px;
  }
`;

export const SubTitle = styled(AriesTitle).attrs({ tag: 'h4' })`
  font-size: 22px;
  font-weight: bold;
  margin-top: 24px;

  @media (max-width: ${ScreenSize.tablet}px) {
    margin-top: 18px;
  }
`;

export const Description = styled(Paragraph).attrs({ variant: 'regular' })`
  margin-top: 24px;

  @media (max-width: ${ScreenSize.tablet}px) {
    margin: 12px 15px;
  }
`;

export const Button = styled(GlintsButton).attrs({ variant: 'yellow' })`
  width: 355px;
  margin-top: 56px;
  height: 53px;

  @media (max-width: ${ScreenSize.tablet}px) {
    margin-top: 16px;
    width: 340px;
  }
`;
