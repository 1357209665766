import React, { useMemo } from 'react';
import { Select } from 'glints-aries';
import { isEmpty } from 'lodash';
import { Control, FieldPath, useController } from 'react-hook-form';
import {
  defineMessage,
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from 'react-intl';

import { ApplicationFieldContainer } from '../../Components/ApplicationFieldContainer/ApplicationFieldContainer';
import { requiredMessage } from '../../constants';
import {
  ExpertApplicationYearsOfExperience,
  ExpertApplicationYearsOfExperienceType,
} from '../../models/ExpertApplication';
import { HelpText } from '../../styles.sc';
import { FormValues } from '../types';

const options: ExpertApplicationYearsOfExperienceType[] = [
  ExpertApplicationYearsOfExperience.UNDER_3,
  ExpertApplicationYearsOfExperience.BETWEEN_3_TO_4,
  ExpertApplicationYearsOfExperience.BETWEEN_4_TO_5,
  ExpertApplicationYearsOfExperience.BETWEEN_5_TO_10,
  ExpertApplicationYearsOfExperience.MORE_THAN_10,
];

export const optionLabels: {
  [key in ExpertApplicationYearsOfExperienceType]: MessageDescriptor;
} = defineMessages({
  UNDER_3: {
    id: 'expert-years-of-experience_under_3',
    defaultMessage: 'Under 3 Years',
  },
  BETWEEN_3_TO_4: {
    id: 'expert-years-of-experience_3_to_4',
    defaultMessage: '3-4 Years',
  },
  BETWEEN_4_TO_5: {
    id: 'expert-years-of-experience_4_to_5',
    defaultMessage: '4-5 Years',
  },
  BETWEEN_5_TO_10: {
    id: 'expert-years-of-experience_5_to_10',
    defaultMessage: '5-10 Years',
  },
  MORE_THAN_10: {
    id: 'expert-years-of-experience_more_than_10',
    defaultMessage: 'More than 10 Years',
  },
});

const label = defineMessage({
  id: 'expert-work-experience_total_years_of_experience',
  defaultMessage: 'Total Years of Working Experience',
});

const placeholder = defineMessage({
  id: 'expert-work-experience_total_years_of_experience_placeholder',
  defaultMessage: 'Select total years',
});

type Props = {
  control: Control<FormValues>;
};

const name: FieldPath<FormValues> = 'workingExperience.totalYearsOfExperience';

export const Component = ({ control }: Props) => {
  const intl = useIntl();

  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    control,
    rules: {
      validate: (value) =>
        isEmpty(value) ? intl.formatMessage(requiredMessage) : undefined,
    },
  });

  const chosenValue = useMemo(() => {
    const choice = options.find((option) => option === field.value);
    return choice ? intl.formatMessage(optionLabels[choice]) : '';
  }, [field.value, intl]);

  const showError = error && isTouched;
  return (
    <>
      <ApplicationFieldContainer
        fieldLabel={label}
        isRequired={true}
        labelFor={name}
      >
        <Select
          {...field}
          id={name}
          name={name}
          value={chosenValue}
          error={showError && error?.message}
          disableTyping={true}
          placeholder={intl.formatMessage(placeholder)}
        >
          {options.map((option) => (
            <Select.Option value={option} key={option}>
              {intl.formatMessage(optionLabels[option])}
            </Select.Option>
          ))}
        </Select>
      </ApplicationFieldContainer>
      <HelpText>
        <FormattedMessage
          id="expert-work-experience_total_years_of_experience-description"
          defaultMessage="Please fill it with your total years of working experience (exclude: internship)"
        />
      </HelpText>
    </>
  );
};

export const TotalYearsOfExperienceField = React.memo(Component);
