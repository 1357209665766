import React, { PropsWithChildren, useState } from 'react';
import { uniqueId } from 'lodash';
import { FormattedMessage, FormattedMessageProps } from 'react-intl';

import { Spacing } from '../../styles.sc';
import { Fieldset, StyledLegend } from './ApplicationMultiFieldsContainer.sc';

type ApplicationMultiFieldContainerProps = {
  legendText: FormattedMessageProps;
  isRequired: boolean;
};

const ApplicationFieldContainerComponent = ({
  children,
  legendText,
  isRequired,
}: PropsWithChildren<ApplicationMultiFieldContainerProps>) => {
  const [id] = useState(uniqueId('prefix-'));
  return (
    <>
      <Fieldset aria-labelledby={id}>
        <StyledLegend id={id} isRequired={isRequired}>
          <FormattedMessage {...legendText} />
        </StyledLegend>
        <Spacing value={8} />
        {children}
      </Fieldset>
    </>
  );
};

export const ApplicationMultiFieldContainer = React.memo(
  ApplicationFieldContainerComponent
);
