import React from 'react';
import { Control } from 'react-hook-form';
import {
  defineMessage,
  defineMessages,
  FormattedMessageProps,
  useIntl,
} from 'react-intl';

import {
  isValidURL,
  testSocialMediaProfileURL,
  testSocialMediaURLPattern,
} from 'src/common/URLUtils';
import { SocialNetworkTypes } from 'src/global/models/User';

import { ApplicationFormTextField } from '../../Components/ApplicationFormTextField/ApplicationFormTextField';
import { FormValues } from '../types';

const validationErrors = defineMessages({
  linkedinProfileUrlReq: {
    id: 'edit-expert-work-experience-modal-error_linkedin_profile_required',
    defaultMessage: 'LinkedIn Profile url is required',
  },
  invalidLinkedInProfileUrl: {
    id: 'edit-expert-work-experience-modal-error_linkedin_profile_invalid',
    defaultMessage: 'Invalid LinkedIn Profile Url',
  },
});

const returnErrorIfInvalidLinkedinUrl = (
  value: string
): FormattedMessageProps | undefined => {
  const socialNetwork = SocialNetworkTypes.LinkedIn;
  if (!value) return validationErrors.linkedinProfileUrlReq;
  if (
    !isValidURL(value) ||
    !testSocialMediaURLPattern(value, socialNetwork) ||
    !testSocialMediaProfileURL(value, socialNetwork)
  ) {
    return validationErrors.invalidLinkedInProfileUrl;
  }
  return undefined;
};

type Props = {
  control: Control<FormValues>;
};

const label = defineMessage({
  id: 'expert-work-experience_linkedin_profile_url',
  defaultMessage: 'LinkedIn Profile URL',
});

const placeholder = defineMessage({
  id: 'expert-work-experience_linkedin-profile-url_placeholder',
  defaultMessage: 'Enter LinkedIn profile URL',
});
const name = 'workingExperience.linkedInProfileUrl';
export const LinkedinField = ({ control }: Props) => {
  const intl = useIntl();
  return (
    <ApplicationFormTextField<FormValues, typeof name>
      controllerProps={{
        name,
        control,
        rules: {
          validate: (inputUrl) => {
            const error = returnErrorIfInvalidLinkedinUrl(inputUrl);
            return error ? intl.formatMessage(error) : undefined;
          },
        },
      }}
      label={label}
      placeholder={placeholder}
      isRequired={true}
    />
  );
};
