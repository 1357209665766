import React, { useState } from 'react';
import { FieldPath, useController, UseControllerProps } from 'react-hook-form';
import { FormattedMessageProps, useIntl } from 'react-intl';

import { typedMemo } from 'src/common/types/typedMemo';
import TextField from 'src/components/LegacyTextField/TextField';

import { ApplicationFieldContainer } from '../ApplicationFieldContainer/ApplicationFieldContainer';
import { ErrorMessage } from '../Forms/ErrorMessage';

type Props<FieldValues, FieldName extends FieldPath<FieldValues>> = {
  controllerProps: UseControllerProps<FieldValues, FieldName>;
  isRequired: boolean;
  label: FormattedMessageProps;
  placeholder: FormattedMessageProps;
};

export const ApplicationFormTextField = typedMemo(function Component<
  FieldValues,
  FieldName extends FieldPath<FieldValues>,
>({
  controllerProps,
  label,
  placeholder,
  isRequired,
}: Props<FieldValues, FieldName>) {
  const intl = useIntl();
  const { name } = controllerProps;

  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({ ...controllerProps });

  // This is to hide old error when user start correcting it again
  // Be default react-hook-form do not reset error
  const [isUserCorrecting, setIsUserCorrecting] = useState(false);

  const handleOnChange = (...event: any[]) => {
    if (!isUserCorrecting && Boolean(error)) {
      setIsUserCorrecting(true);
    }
    return field.onChange(...event);
  };

  const handleOnBlur = () => {
    setIsUserCorrecting(false);
    return field.onBlur();
  };
  const showError = error && (isTouched || isDirty) && !isUserCorrecting;
  return (
    <>
      <ApplicationFieldContainer
        fieldLabel={label}
        isRequired={isRequired}
        labelFor={name}
      >
        <TextField
          {...field}
          id={name}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          placeholder={intl.formatMessage(placeholder)}
          removeFloatingLabel={true} // we use fieldLabel in ApplicationFieldContainer instead
          label=""
          status={showError ? 'error' : 'success'}
          aria-invalid={showError ? 'true' : 'false'}
          aria-errormessage={`${name}Error`}
        />
      </ApplicationFieldContainer>
      {showError && (
        <ErrorMessage id={`${name}Error`}>{error.message}</ErrorMessage>
      )}
    </>
  );
});
