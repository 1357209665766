import { ScreenSize, Typography } from 'glints-aries';
import styled from 'styled-components';

const { Title: AriesTitle, Paragraph } = Typography;

export const LoaderContainer = styled.div`
  text-align: center;
  margin: 48px 24px;
  @media (min-width: ${ScreenSize.desktopS}px) {
    margin: 64px 0;
  }
`;

export const WelcomeMessageSection = styled.div`
  text-align: center;
  margin: 0 24px 48px 24px;
  @media (min-width: ${ScreenSize.desktopS}px) {
    margin: 0 0 64px 0;
  }
`;

export const Title = styled(AriesTitle).attrs({ tag: 'h3' })`
  font-size: 24px;
  font-weight: bold;
  @media (max-width: ${ScreenSize.mobileL}px) {
    font-size: 18px;
  }
`;

export const Description = styled(Paragraph).attrs({ variant: 'regular' })`
  margin-top: 16px;
  color: #524e4e;
  @media (max-width: ${ScreenSize.mobileL}px) {
    font-size: 14px;
  }
`;

export const ProfileInfoContainer = styled.div`
  margin-bottom: 60px;
`;
