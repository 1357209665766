import { defineMessage } from 'react-intl';

import { ExpertClassApplicationClassTimeType } from './ExpertClassApplication/models/ExpertClassApplication';

export const classTimeMessages: {
  [key in ExpertClassApplicationClassTimeType]: string;
} = {
  TIME_10_TO_12: '10:00 - 12:00',
  TIME_13_TO_15: '13:00 - 15:00',
  TIME_17_TO_19: '17:00 - 19:00',
  TIME_19_TO_21: '19:00 - 21:00',
};

export const requiredMessage = defineMessage({
  id: 'expert-platform-application_field_required',
  defaultMessage: 'Required',
});
