import React, { useContext, useEffect, useState } from 'react';
import { Loading } from 'glints-aries';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ROUTES } from 'src/common/routes';
import GlintsContainer from 'src/components/GlintsContainer';
import { getUser } from 'src/selectors/user';

import { MainHeaderHeightContext } from '../MainContainer/Context';
import { ExpertGetStarted } from './Components/ExpertGetStarted';
import { EditExpertProfileInfoModal } from './Components/Modals/EditExpertProfileInfoModal/EditExpertProfileInfoModal';
import { EditProfileInfoFormValues } from './Components/Modals/EditExpertProfileInfoModal/types';
import { ProfileInformation } from './Components/ProfileInformation/ProfileInformation';
import { ProfileInformationData } from './Components/ProfileInformation/types';
import { ExpertApplicationForm } from './ExpertApplication/ExpertApplicationForm';
import { useGetActiveExpertApplication } from './ExpertApplication/hooks/useGetActiveExpertApplication';
import * as S from './ExpertApplicationPage.sc';
import ExpertApplicationRejectedPage from './ExpertApplicationRejectedPage';
import { ExpertApplicationSentPage } from './ExpertApplicationSentPage';
import { useCreateUnApproveExpertApplication } from './hooks/useCreateUnApproveExpertApplication';
import { useUpdateExpertApplicationPersonalInformation } from './hooks/useUpdateExpertApplicationPersonalInformation';
import { ExpertApplicationStatus } from './models/ExpertApplication';
import { UpdateExpertPersonalInformationInput } from './models/inputs/UpdateExpertPersonalInformationInput';
import { ScrollTagretSpacing } from './styles.sc';

const Footer = dynamic(() => import('src/components/Footer/FooterPsychFlat'));

function isApplicationReviewInProgress(status: ExpertApplicationStatus) {
  return (
    status === ExpertApplicationStatus.reviewNeeded ||
    status === ExpertApplicationStatus.reviewing
  );
}

const WelcomeMessage = () => (
  <>
    <FormattedMessage
      defaultMessage="YOU ARE ON YOUR WAY TO BECOMING AN EXPERT"
      id="expert-application-form_title"
      tagName={S.Title}
    />
    <FormattedMessage
      defaultMessage="Baby steps. You’ve started so don’t give up now! Keep going."
      id="expert-application-form_description"
      tagName={S.Description}
    />
  </>
);

const ExpertApplicationPage: NextPage = () => {
  const mainHeaderHeight = useContext(MainHeaderHeightContext);

  const {
    mutate: createNewExpertApplication,
    loading: isMutationInProgress,
    isUserAlreadyExpert,
    error,
  } = useCreateUnApproveExpertApplication();

  const {
    expertApplication,
    isExpertApplicationLoading,
    expertApplicationFetchError,
  } = useGetActiveExpertApplication();

  const { updatePersonalInformation } =
    useUpdateExpertApplicationPersonalInformation();

  const user = useSelector(getUser);

  const userRequiredInfoFilled: boolean =
    Boolean(user.firstName) &&
    Boolean(user.lastName) &&
    Boolean(user.email) &&
    Boolean(user.phone);

  // this will only be consumed if the `userRequiredInfoFilled` is false(the required info is not filled)
  const [editProfileInfoModalVisible, setEditProfileInfoModalVisibility] =
    useState(!userRequiredInfoFilled);

  useEffect(() => {
    if (
      (!isExpertApplicationLoading &&
        expertApplication?.status === ExpertApplicationStatus.approved) ||
      (!isMutationInProgress && isUserAlreadyExpert)
    ) {
      Router.push(`/${ROUTES.expert}`);
    }
  }, [
    isExpertApplicationLoading,
    expertApplication?.status,
    isUserAlreadyExpert,
    isMutationInProgress,
    error,
  ]);

  const activeApplicationNotExist =
    !isExpertApplicationLoading &&
    expertApplication === null &&
    !expertApplicationFetchError;

  useEffect(() => {
    if (activeApplicationNotExist) {
      createNewExpertApplication();
    }
  }, [createNewExpertApplication, activeApplicationNotExist]);

  const handleUpdateExpertApplicationPersonalInformation = async (
    values: EditProfileInfoFormValues
  ): Promise<ProfileInformationData> => {
    const input: UpdateExpertPersonalInformationInput = {
      ...values,
    };
    const result = await updatePersonalInformation({
      variables: {
        data: input,
      },
    });
    const data = result.data.updateExpertApplicationPersonalInformation;
    return {
      user: {
        ...data.user,
      },
      secondaryEmail: data.additionalInformation?.secondaryEmail,
    };
  };

  // Whenever any of this condition happen some useEffect
  // would be start or in process of running
  // and page will be loaded soon
  if (
    isExpertApplicationLoading ||
    isMutationInProgress ||
    activeApplicationNotExist ||
    expertApplication.status === ExpertApplicationStatus.approved
  ) {
    return (
      <S.LoaderContainer>
        <Loading />
      </S.LoaderContainer>
    );
  }
  if (!userRequiredInfoFilled) {
    return (
      <>
        <EditExpertProfileInfoModal
          user={user}
          onClose={() => setEditProfileInfoModalVisibility(false)}
          secondaryEmail={
            expertApplication?.additionalInformation?.secondaryEmail
          }
          isModalOpen={editProfileInfoModalVisible}
          updateProfileMutation={
            handleUpdateExpertApplicationPersonalInformation
          }
          hideProfilePicUploader={true}
        />
        <ExpertGetStarted
          ctaText={{
            id: 'expert-application_get-started',
            defaultMessage: 'Get Started',
          }}
          contentText={{
            id: 'expert-application_get-started_description',
            defaultMessage:
              'Join Glints in realizing human potential and nurturing young talents and professionals. Kickstart your journey as an expert by opening your own ExpertClass!',
          }}
          onCTAClick={function () {
            setEditProfileInfoModalVisibility(true);
          }}
        />
        <Footer />
      </>
    );
  }

  if (isApplicationReviewInProgress(expertApplication.status)) {
    return <ExpertApplicationSentPage />;
  }

  if (expertApplication.status === ExpertApplicationStatus.rejected) {
    return (
      <ExpertApplicationRejectedPage
        rejectedReasons={expertApplication.rejectionReasons}
      />
    );
  }

  const {
    additionalInformation: { secondaryEmail },
  } = expertApplication;

  return (
    <ScrollTagretSpacing mainHeaderHeight={mainHeaderHeight}>
      <S.ProfileInfoContainer>
        <ProfileInformation
          user={user}
          secondaryEmail={secondaryEmail}
          updateProfileMutation={
            handleUpdateExpertApplicationPersonalInformation
          }
        />
      </S.ProfileInfoContainer>
      <S.WelcomeMessageSection>
        <WelcomeMessage />
      </S.WelcomeMessageSection>
      <GlintsContainer>
        <ExpertApplicationForm expertApplication={expertApplication} />
      </GlintsContainer>

      <Footer />
    </ScrollTagretSpacing>
  );
};

export default ExpertApplicationPage;
