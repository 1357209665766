import { Greyscale, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const Fieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
`;

export const SectionLegend = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: relative;
  border: none;
  border-bottom: 2px solid ${Greyscale.softgrey};
  margin-bottom: 20px;
  padding-bottom: 12px;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  @media (min-width: ${ScreenSize.desktopS}px) {
    margin-bottom: 20px;
  }
`;

export const ChildrenContainer = styled.div`
  @media (min-width: ${ScreenSize.desktopS}px) {
    width: 630px;
  }
`;
