import React from 'react';
import { Control } from 'react-hook-form';
import { defineMessage, useIntl } from 'react-intl';

import { ApplicationFormTextField } from '../../Components/ApplicationFormTextField/ApplicationFormTextField';
import { requiredMessage } from '../../constants';
import { FormValues } from '../types';

type Props = {
  control: Control<FormValues>;
};

const label = defineMessage({
  id: 'expert-work-experince_current_position',
  defaultMessage: 'Job Title',
});

const placeholder = defineMessage({
  id: 'expert-work-experience_job-title_placeholder',
  defaultMessage: 'Enter job title',
});

const name = 'currentJob.title';

export const TitleField = ({ control }: Props) => {
  const intl = useIntl();
  return (
    <ApplicationFormTextField<FormValues, typeof name>
      controllerProps={{
        name,
        control,
        rules: {
          required: intl.formatMessage(requiredMessage),
        },
      }}
      label={label}
      placeholder={placeholder}
      isRequired={true}
    />
  );
};
