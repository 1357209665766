import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ApplicationFormSection } from '../../Components/ApplicationFormSection/ApplicationFormSection';
import { Spacing } from '../../styles.sc';
import { FormValues, SectionKey } from '../types';
import { CompanyField } from './CompanyField';
import { CompanySizeField } from './CompanySizeField';
import { JobLevelField } from './JobLevelField';
import { StartMonthAndYearFields } from './StartMonthAndYearFields/StartMonthAndYearFields';
import { TitleField } from './TitleField';

const id: SectionKey = 'currentJob';

const Component = () => {
  const { control } = useFormContext<FormValues>();
  return (
    <ApplicationFormSection
      id={id}
      titleMessage={{
        id: 'expert-application_current-job',
        defaultMessage: 'CURRENT JOB',
      }}
    >
      <CompanyField control={control} />
      <Spacing value={16} />
      <CompanySizeField control={control} />
      <Spacing value={16} />
      <StartMonthAndYearFields control={control} />
      <Spacing value={16} />
      <TitleField control={control} />
      <Spacing value={16} />
      <JobLevelField control={control} />
    </ApplicationFormSection>
  );
};

export const CurrentJobSection = React.memo(Component);
