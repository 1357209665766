import React, { ReactNode } from 'react';
import { Button, Divider, HelpIcon, ScreenSize } from 'glints-aries';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import { useMediaQuery } from 'src/common/hooks/useMediaQuery';

import {
  ActionButtonContainer,
  CancelButton,
  CompleteInfoLabel,
  Container,
  NeedHelpContainer,
  ProgressCircleContainer,
  SubTitle,
  Title,
} from './ApplicationFormProgress.sc';
import { ChecklistItem } from './ChecklistItem/CheclistItem';
import { PercentageProgressCircle } from './PercentageProgressCircle/PercentageProgressCircle';

type Section = {
  scrollTargetId?: string;
  label: string;
  isCompleted: boolean;
};

type ApplicationFormProgressProps = {
  completionPercent: number;
  title: string;
  subtitle: ReactNode;
  sections: Section[];
  onSubmit: () => void;
  onCancel: () => void;
  isReadOnly?: boolean;
  hideProgress?: boolean;
  submitButtonText: string;
  cancelButtonText: string;
};

export const ApplicationFormProgress = React.memo(
  function ApplicationFormProgressDetails({
    sections,
    title,
    subtitle,
    completionPercent,
    onSubmit,
    onCancel,
    isReadOnly = false,
    hideProgress = false,
    submitButtonText,
    cancelButtonText,
  }: ApplicationFormProgressProps) {
    const isDesktop = useMediaQuery(`(min-width: ${ScreenSize.desktopS}px)`);
    return (
      <>
        <Container>
          <Title>{title}</Title>
          {!hideProgress && (
            <ProgressCircleContainer>
              <PercentageProgressCircle percentage={completionPercent} />
            </ProgressCircleContainer>
          )}
          <SubTitle>{subtitle}</SubTitle>
          <Divider theme="grey" />
          <CompleteInfoLabel>
            <FormattedMessage
              id="expert_platform-application_completion_label"
              defaultMessage="Complete the Information"
            />
          </CompleteInfoLabel>
          {sections.map(({ label, isCompleted, scrollTargetId }) => {
            const shouldCreateScrollToLink = scrollTargetId && !isDesktop;
            const item = (
              <ChecklistItem
                key={label}
                label={label}
                isCompleted={isCompleted}
              />
            );

            return shouldCreateScrollToLink ? (
              <Link
                key={scrollTargetId}
                href={scrollTargetId ? `#${scrollTargetId}` : ''}
              >
                {/* Need onClick to trigger :target css seelctor. Seems as a bug in Link */}
                <a
                  onClick={() => (window.location.hash = `#${scrollTargetId}`)}
                >
                  {item}
                </a>
              </Link>
            ) : (
              item
            );
          })}
          <If condition={!isReadOnly}>
            <ActionButtonContainer>
              <Button
                variant="solid-blue"
                type="button"
                block={true}
                small={true}
                onClick={onSubmit}
                disabled={completionPercent < 100}
              >
                {submitButtonText}
              </Button>
              <CancelButton
                variant="ghost"
                type="button"
                block={true}
                small={true}
                onClick={onCancel}
              >
                {cancelButtonText}
              </CancelButton>
            </ActionButtonContainer>
          </If>
          <a href="mailto:hi@glints.com">
            <NeedHelpContainer>
              <HelpIcon />
              <FormattedMessage
                id="expert_platform-application_need_help_label"
                defaultMessage="Need help? Please contact hi@glints.com"
              />
            </NeedHelpContainer>
          </a>
        </Container>
      </>
    );
  }
);
