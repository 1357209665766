import { useMemo } from 'react';
import moment from 'moment';

export const useYearAndMonthOptions = (forwardYearDuration = 0) => {
  const monthOptions = moment
    .months()
    .map((month) => ({ dataValue: month, label: month }));

  const currentYear = moment().year();
  const years = Array.from({ length: 61 }, (_, i) => currentYear - i);
  const yearOptions = years.map((year) => ({
    dataValue: year.toString(),
    label: year.toString(),
  }));

  const forwardYearOptions = useMemo(() => {
    if (!forwardYearDuration) return yearOptions;

    const currentYear = moment().year();
    const years = [];
    for (
      let i = currentYear + forwardYearDuration;
      i >= currentYear - 60;
      i--
    ) {
      years.push(i);
    }
    return years.map((year) => ({
      dataValue: year.toString(),
      label: year.toString(),
    }));
  }, [forwardYearDuration, yearOptions]);

  return { monthOptions, yearOptions, forwardYearOptions };
};
