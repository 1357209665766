import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { getUser } from 'src/selectors/user';

import { getActiveExpertApplication } from '../../graphql';
import { ExpertApplication } from '../../models/ExpertApplication';

export const useGetActiveExpertApplication = () => {
  const user = useSelector(getUser);

  const {
    data,
    loading: isExpertApplicationLoading,
    error: expertApplicationFetchError,
    refetch,
  } = useQuery<{
    getActiveExpertApplication: ExpertApplication;
  }>(getActiveExpertApplication, { fetchPolicy: 'network-only' });
  const expertApplication = data?.getActiveExpertApplication;

  useEffect(() => {
    refetch();
  }, [refetch, user]);

  return {
    expertApplication,
    isExpertApplicationLoading,
    expertApplicationFetchError,
  };
};
