import React, { useEffect, useState } from 'react';
import { Button, Loading, Modal } from 'glints-aries';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Bold } from 'src/common/messages/RichTextFormatting';
import { AlertBox } from 'src/components/AlertBox';
import {
  hideWhatsappMessagingAction,
  showWhatsappMessagingAction,
} from 'src/modules/Whatsapp/actions';
import {
  hideZendeskMessagingAction,
  showZendeskMessagingAction,
} from 'src/modules/Zendesk/actions';

import { Spacing } from '../../styles.sc';
import {
  ContentTitle,
  ModalContent,
  ModalImage,
  ModalTitle,
} from './SubmitApplicationModal.sc';

type SubmitApplicationModalProps = {
  isOpen: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
  isSubmitInProgress: boolean;
};

export const SubmitApplicationModal = React.memo(
  function SubmitApplicationModalComponent({
    isOpen,
    onSubmit,
    onClose,
    isSubmitInProgress,
  }: SubmitApplicationModalProps) {
    const dispatch = useDispatch();

    useEffect(() => {
      if (isOpen) {
        dispatch(hideZendeskMessagingAction());
        dispatch(hideWhatsappMessagingAction());
      } else {
        dispatch(showZendeskMessagingAction());
        dispatch(showWhatsappMessagingAction());
      }

      return function onUnmount() {
        dispatch(showZendeskMessagingAction());
        dispatch(showWhatsappMessagingAction());
      };
    }, [isOpen, dispatch]);
    const [error, setError] = useState<Error>(null);

    const handleSubmit = async () => {
      try {
        await onSubmit();
      } catch (e) {
        console.error(e);
        setError(e);
      }
    };
    return (
      <>
        <Modal
          isVisible={isOpen}
          onClose={onClose}
          title={
            <ModalTitle>
              <FormattedMessage
                id="expert-application-submit-modal-title"
                defaultMessage="SEND APPLICATION"
              />
            </ModalTitle>
          }
          footer={[
            <Button
              key="CancelButton"
              variant="ghost"
              onClick={onClose}
              small={true}
              type="button"
            >
              <FormattedMessage
                id="expert-application-submit-modal-no-button"
                defaultMessage="DON'T SEND YET"
              />
            </Button>,

            <Button
              key="SaveButton"
              disabled={isSubmitInProgress}
              variant="solid-blue"
              onClick={handleSubmit}
              small={true}
              type="submit"
            >
              <Choose>
                <When condition={isSubmitInProgress}>
                  <Loading />
                </When>
                <Otherwise>
                  <FormattedMessage
                    id="expert-application-submit-yes-button"
                    defaultMessage="YES"
                  />
                </Otherwise>
              </Choose>
            </Button>,
          ]}
        >
          <ModalContent>
            <ModalImage />
            <ContentTitle>
              <FormattedMessage
                id="expert-application-submit-modal-content-title"
                defaultMessage="Your application will be sent!"
              />
            </ContentTitle>
            <FormattedMessage
              id="expert-application-submit-modal-content"
              defaultMessage={
                "Make sure you've reviewed your profile. A friendly reminder, " +
                '<bold>you can only apply once.</bold>' +
                '{br} {br}' +
                'Would you like to proceed and send your {br}' +
                'Expert application?'
              }
              values={{
                bold: Bold,
                br: <br />,
              }}
            />
          </ModalContent>
          {error && (
            <>
              <Spacing value={10} />
              <AlertBox
                message={'Oops something went wrong. Please try again.'}
                variant={'error'}
              />
            </>
          )}
        </Modal>
      </>
    );
  }
);
