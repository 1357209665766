import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, ExternalLinkIcon, Loading, Modal } from 'glints-aries';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ROUTES } from 'src/common/routes';
import { AlertBox } from 'src/components/AlertBox';
import {
  hideWhatsappMessagingAction,
  showWhatsappMessagingAction,
} from 'src/modules/Whatsapp/actions';
import {
  hideZendeskMessagingAction,
  showZendeskMessagingAction,
} from 'src/modules/Zendesk/actions';

import { Spacing } from '../../styles.sc';
import {
  ContentTitle,
  ExistingClassesLink,
  ModalContent,
  ModalImage,
  ModalTitle,
} from './CancelApplicationModal.sc';
import { cancelExpertApplication } from './graphql';

type CancelApplicationModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
};

const ClassesLink = (...chunks: any[]) => {
  return (
    <ExistingClassesLink href={`/${ROUTES.expertClass}`} passHref={true}>
      <a>
        {chunks} <ExternalLinkIcon />
      </a>
    </ExistingClassesLink>
  );
};
export const CancelApplicationModal = React.memo(
  function CancelApplicationModalComponent({
    isOpen,
    onCancel,
    onClose,
  }: CancelApplicationModalProps) {
    const dispatch = useDispatch();

    useEffect(() => {
      if (isOpen) {
        dispatch(hideZendeskMessagingAction());
        dispatch(hideWhatsappMessagingAction());
      } else {
        dispatch(showZendeskMessagingAction());
        dispatch(showWhatsappMessagingAction());
      }

      return function onUnmount() {
        dispatch(showZendeskMessagingAction());
        dispatch(showWhatsappMessagingAction());
      };
    }, [isOpen, dispatch]);
    const [mutation, { loading }] = useMutation(cancelExpertApplication);
    const [error, setError] = useState<Error>(null);

    const handleSubmit = async () => {
      try {
        await mutation();
        onCancel();
      } catch (e) {
        console.error(e);
        setError(e);
      }
    };

    return (
      <>
        <Modal
          isVisible={isOpen}
          onClose={onClose}
          title={
            <ModalTitle>
              <FormattedMessage
                id="expert-application-cancel-modal-title"
                defaultMessage="LEAVE APPLICATION"
              />
            </ModalTitle>
          }
          footer={[
            <Button
              key="CancelButton"
              variant="ghost"
              onClick={onClose}
              small={true}
              type="button"
            >
              <FormattedMessage
                id="expert-application-cancel-modal-no-button"
                defaultMessage="NO"
              />
            </Button>,

            <Button
              key="SaveButton"
              disabled={loading}
              variant="solid-blue"
              onClick={handleSubmit}
              small={true}
              type="submit"
            >
              <Choose>
                <When condition={loading}>
                  <Loading />
                </When>
                <Otherwise>
                  <FormattedMessage
                    id="expert-application-cancel-yes-button"
                    defaultMessage="YES"
                  />
                </Otherwise>
              </Choose>
            </Button>,
          ]}
        >
          <ModalContent>
            <ModalImage />
            <ContentTitle>
              <FormattedMessage
                id="expert-application-cancel-modal-content-title"
                defaultMessage="Are you sure you want to leave your Expert application?"
              />
            </ContentTitle>
            <FormattedMessage
              id="expert-application-cancel-modal-content"
              defaultMessage={
                "Your application will be saved and won't be lost." +
                '{br} {br}' +
                "You can always apply to be our Expert when you're ready!"
              }
              values={{
                br: <br />,
              }}
            />
            <br />
            <br />

            <FormattedMessage
              id="expert-application-cancel-modal-link"
              tagName="p"
              defaultMessage="View existing classes <ClassesLink>here</ClassesLink>"
              values={{
                ClassesLink,
              }}
            />
          </ModalContent>

          {error && (
            <>
              <Spacing value={10} />
              <AlertBox
                message={'Oops something went wrong. Please try again.'}
                variant={'error'}
              />
            </>
          )}
        </Modal>
      </>
    );
  }
);
