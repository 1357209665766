import { Greyscale } from 'glints-aries';
import styled from 'styled-components';

export const StyledLabelMessage = styled.div<{ isRequired: boolean }>`
  font-size: 16px;
  color: ${Greyscale.black};
  ${({ isRequired }) =>
    isRequired
      ? `&::after {
        content: "*";
        color: red;
      }`
      : ''}
`;
