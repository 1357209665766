import React, { PropsWithChildren } from 'react';
import { FormattedMessage, FormattedMessageProps } from 'react-intl';

import {
  ChildrenContainer,
  Fieldset,
  SectionLegend,
} from './ApplicationFormSection.sc';

type ApplicationSectionProps = {
  titleMessage: FormattedMessageProps;
  id: string;
};

const ApplicationFormSectionComponent = ({
  titleMessage,
  children,
  id,
}: PropsWithChildren<ApplicationSectionProps>) => {
  return (
    <Fieldset>
      <SectionLegend id={id}>
        <FormattedMessage {...titleMessage} />
      </SectionLegend>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Fieldset>
  );
};

export const ApplicationFormSection = React.memo(
  ApplicationFormSectionComponent
);
