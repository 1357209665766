import { Button, Greyscale, SecondaryColor } from 'glints-aries';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 18px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  color: ${Greyscale.black};
  border: 1px solid ${Greyscale.lightgrey};
  width: fit-content;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: ${Greyscale.black};
`;

export const CompleteInfoLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${Greyscale.black};
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${Greyscale.black};
`;

export const ProgressCircleContainer = styled.div`
  margin: auto;
`;

export const NeedHelpContainer = styled.div`
  display: flex;
  gap: 8px;
  color: ${SecondaryColor.actionblue};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
`;

export const CancelButton = styled(Button)`
  button {
    border-color: #979797;
    color: #979797;
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;
