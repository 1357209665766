import React, { useCallback, useState } from 'react';
import Router from 'next/router';
import { FormState, useFormContext } from 'react-hook-form';
import { defineMessage, FormattedMessageProps, useIntl } from 'react-intl';

import { ROUTES } from 'src/common/routes';

import { ApplicationFormProgress } from '../../Components/ApplicationFormProgress/ApplicationFormProgress';
import { CancelApplicationModal } from '../CancelApplicationModal/CancelApplicationModal';
import { SubmitApplicationModal } from '../SubmitApplicationModal/SubmitApplicationModal';
import { FormValues, SectionKey } from '../types';

const sections: SectionKey[] = ['workingExperience', 'currentJob'];
type CompletionStatus = Record<SectionKey, boolean>;

const calculateFormCompletionStatus = ({
  errors,
}: FormState<FormValues>): CompletionStatus => {
  return {
    workingExperience: !errors?.workingExperience,
    currentJob: !errors?.currentJob,
  };
};

const calculateCompletionPercent = (completionStatus: CompletionStatus) => {
  const completionStatuesValues = Object.values(completionStatus);
  const numerator = completionStatuesValues.reduce(
    (result, sectionCompletionStatus) =>
      result + Number(sectionCompletionStatus),
    0
  );
  if (numerator === 0) {
    return 0;
  }
  const denominator = completionStatuesValues.length;
  return (numerator * 100) / denominator;
};

const titleMessage = defineMessage({
  id: 'expert-application-completion_status_title',
  defaultMessage: 'APPLICATION COMPLETION',
});

const subtitleMessage = defineMessage({
  id: 'expert-application-completion_status_sub_title',
  defaultMessage: 'Complete profile to increase your credibility.',
});

const submitButtonText = defineMessage({
  id: 'expert-application_send-application-button',
  defaultMessage: 'SEND APPLICATION',
});

const cancelButtonText = defineMessage({
  id: 'expert-application_cancel-application-button',
  defaultMessage: 'CANCEL APPLICATION',
});

const sectionMessages: {
  [key in SectionKey]: FormattedMessageProps;
} = {
  workingExperience: defineMessage({
    id: 'expert-application-completion_status-work_exp',
    defaultMessage: 'Working Experience',
  }),
  currentJob: defineMessage({
    id: 'expert-application-completion_status-current_job',
    defaultMessage: 'Current Job',
  }),
};

type Props = {
  onSubmit: () => Promise<void>;
  isSubmitInProgress: boolean;
};

export const ProgressPanel = React.memo(
  function ExpertApplicationProgressPanel({
    onSubmit,
    isSubmitInProgress,
  }: Props) {
    const intl = useIntl();
    const title = intl.formatMessage(titleMessage);
    const subtitle = intl.formatMessage(subtitleMessage);

    const { formState } = useFormContext<FormValues>();
    const completionStatus = calculateFormCompletionStatus(formState);

    const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);

    const openSubmitApplicationModal = useCallback(() => {
      setIsSubmitModalOpen(true);
    }, [setIsSubmitModalOpen]);

    const closeSubmitApplicationModal = useCallback(() => {
      setIsSubmitModalOpen(false);
    }, [setIsSubmitModalOpen]);

    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

    const openCancelApplicationModal = useCallback(() => {
      setIsCancelModalOpen(true);
    }, [setIsCancelModalOpen]);

    const closeCancelApplicationModal = useCallback(() => {
      setIsCancelModalOpen(false);
    }, [setIsCancelModalOpen]);

    const onCancel = useCallback(() => {
      Router.push(`/${ROUTES.expertClass}`);
      closeCancelApplicationModal();
    }, [closeCancelApplicationModal]);

    return (
      <>
        <SubmitApplicationModal
          isOpen={isSubmitModalOpen}
          onClose={closeSubmitApplicationModal}
          onSubmit={async () => {
            await onSubmit();
            closeSubmitApplicationModal();
          }}
          isSubmitInProgress={isSubmitInProgress}
        />
        <CancelApplicationModal
          isOpen={isCancelModalOpen}
          onClose={closeCancelApplicationModal}
          onCancel={onCancel}
        />
        <ApplicationFormProgress
          title={title}
          subtitle={subtitle}
          sections={sections.map((section) => ({
            scrollTargetId: section,
            label: intl.formatMessage(sectionMessages[section]),
            isCompleted: completionStatus[section],
          }))}
          completionPercent={calculateCompletionPercent(completionStatus)}
          onSubmit={openSubmitApplicationModal}
          onCancel={openCancelApplicationModal}
          submitButtonText={intl.formatMessage(submitButtonText)}
          cancelButtonText={intl.formatMessage(cancelButtonText)}
        />
      </>
    );
  }
);
