import { useState } from 'react';

import { hasLocalStorage } from '../utils/hasLocalStorage';

const updateLocalStorageIfAvailable = (key: string, value: string | null) => {
  if (value !== undefined || value !== null) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

export const useLocalStorage = (key: string, initialValue: string = null) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (!hasLocalStorage()) {
      return initialValue;
    }

    return localStorage.getItem(key) ?? initialValue;
  });

  const setValue = (
    // Allow value to be a function so we have similar API as useState
    setValueAction:
      | string
      | null
      | ((prevValue: string | null) => string | null)
  ) => {
    if (setValueAction instanceof Function) {
      setStoredValue((previousValue: string) => {
        const finalValue = setValueAction(previousValue);
        updateLocalStorageIfAvailable(key, finalValue);
        return finalValue;
      });
      return;
    }
    setStoredValue(setValueAction);
    updateLocalStorageIfAvailable(key, setValueAction);
  };
  return [storedValue, setValue] as const;
};
