import { ScreenSize, Typography } from 'glints-aries';
import styled from 'styled-components';

const { Title: AriesTitle, Paragraph } = Typography;

export const Container = styled.div`
  margin-top: 80px;
  margin-bottom: 120px;
  text-align: center;
`;

export const Title = styled(AriesTitle).attrs({ tag: 'h3' })`
  margin-top: 32px;
  font-size: 26px;
  font-weight: bold;

  @media (max-width: ${ScreenSize.tablet}px) {
    margin: 32px 20px 0px;
  }
`;

export const Description = styled(Paragraph).attrs({ variant: 'subtitle' })`
  text-align: center;
  margin: 16px 0px 288px;
  font-weight: normal;

  @media (max-width: ${ScreenSize.tablet}px) {
    margin: 16px 20px 0px;
  }
`;

export const Image = styled.div<{ url: string }>`
  width: 188.89px;
  height: 176.84px;
  margin: 0px auto;
  background: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
`;
