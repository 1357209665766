import React, { useEffect } from 'react';
import { Loading } from 'glints-aries';
import { isEmpty } from 'lodash';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import { FormattedMessage } from 'react-intl';

import * as S from './ExpertApplicationRejectedPage.sc';
import { useReviseExpertApplication } from './hooks/useReviseExpertApplication';
import { ExpertApplicationRejectionReasonOutput } from './models/ExpertApplication';

const Footer = dynamic(() => import('src/components/Footer/FooterPsychFlat'));

type Props = {
  rejectedReasons: ExpertApplicationRejectionReasonOutput;
};
const ExpertApplicationRejectedPage: NextPage<Props> = ({
  rejectedReasons: { selectedRejectionReasons, otherRejectionReason },
}) => {
  const hasRejectedReasons =
    !isEmpty(selectedRejectionReasons) || Boolean(otherRejectionReason);
  const {
    mutate: reviseExpertApplication,
    loading: isRevisingApplication,
    isAlreadyRevised,
    error,
  } = useReviseExpertApplication();

  const handleReviseExpertApplication = async () => {
    await reviseExpertApplication();
  };

  useEffect(() => {
    if (isAlreadyRevised) {
      Router.reload();
    }
  }, [isAlreadyRevised, error]);

  if (isAlreadyRevised) {
    return (
      <S.Container>
        <Loading />
      </S.Container>
    );
  }
  return (
    <>
      <S.Container>
        <S.Image url="/images/expert-platform/expert-application-rejected-bg.png" />
        <FormattedMessage
          id="expert-application-rejected_title"
          defaultMessage="Sorry, your application didn't match our requirements, because"
          tagName={S.ApplicationRejectionTitle}
        />
        {hasRejectedReasons && (
          <S.RejectedReasonsContainer>
            <S.List>
              {Object.values(selectedRejectionReasons).map((e) => {
                return (
                  <FormattedMessage
                    key={e.id}
                    defaultMessage={e.defaultTranslation}
                    id={e.translationKey}
                    tagName={S.ListItem}
                  />
                );
              })}
              {otherRejectionReason && (
                <S.ListItem key={otherRejectionReason.id}>
                  {otherRejectionReason.reasonForRejection}
                </S.ListItem>
              )}
            </S.List>
          </S.RejectedReasonsContainer>
        )}
        <FormattedMessage
          id="expert-application-rejected_whats_next"
          defaultMessage="What's next? You can try to update the application and submit again."
          tagName={S.WhatsNextText}
        />
        <S.Button
          variant="yellow"
          onClick={handleReviseExpertApplication}
          disabled={isRevisingApplication}
        >
          <FormattedMessage
            id="expert-application-rejected_update_application"
            defaultMessage="Update my Application"
          />
        </S.Button>
      </S.Container>
      <Footer />
    </>
  );
};
export default ExpertApplicationRejectedPage;
